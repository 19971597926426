<template>
  <main>
    <BlueprintBlackLogo class="logo" />
    <section class="main-container">
      <p class="welcome-text">Personalize</p>
      <router-view />
      <div class="modal-area">
      <div v-if="showModal" class="modal">Please choose a base to continue!</div>
      </div>
      <SimpleButton label="Next" @click="nextHandler" />
      <router-link to="" class="back-link" @click="backHandler">
        <img :src="lt" alt="" />
        <span style="margin-left: 10px;">Back</span>
      </router-link>
    </section>
  </main>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import BlueprintBlackLogo from '@/components/ui/BlueprintBlackLogo.vue'
import SimpleButton from '@/components/ui/SimpleButton'
import lt from '@/assets/icons/lt.svg'
import UserAPI from '@/api/UserAPI'
import { userNames } from '@/store/modules/user'
import { ref } from 'vue'

const router = useRouter()
const store = useStore()
const showModal = ref(false)

const nextHandler = async () => {
  if (!store.state.avatar?.base) {
    showModal.value = true
  } else {
    showModal.value = false
  }

  const path = location.pathname
  if (path === '/personalize/avatar-builder' || path === '/personalize/avatar-builder/1') {
    await router.push('/personalize/avatar-builder/2')
    // showModal.value = false
  } else if (path === '/personalize/avatar-builder/2') {
    await router.push('/personalize/avatar-builder/3')
  } else {
    try {
      if (store.state.user?.avatar?.base) {
        await UserAPI.updateMyAvatar(store.state.avatar)
      } else {
        await UserAPI.createMyAvatar(store.state.avatar)
      }
      store.commit(userNames.setAvatar, store.state.avatar)
      await router.push('/personalize/avatar-final')
    } catch (error) {
      console.log(error)
    }
  }
}

const backHandler = async () => {
  const path = location.pathname
  if (path === '/personalize/avatar-builder' || path === '/personalize/avatar-builder/1') {
      await router.push('/personalize/select-house')
  } else {
  router.back()
  }
}
</script>

<style scoped>
.logo {
  margin-top: 40px;
  margin-left: 100px; /* fix it later? */
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.welcome-text {
  text-transform: uppercase;
  color: #FF8217;
}

.back-link {
  margin-right: 15px;
  margin-top: 20px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  text-align: right;
  text-decoration: none;
  font-size: 16px;
  color: #FF8217;
}
.modal{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
color: white;
width: 249px;
height: 30px;
/* Alert */
background: #B32D22;
border-radius: 3px;
}
.modal-area{
  margin-bottom: 15px;
  height: 30px;
}

</style>
